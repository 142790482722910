<template>
  <div class="form-group" v-if="visible">
    <label class="col-form-label" :for="id" v-if="!hideLabel">{{
      label
    }}</label>

    <b-form-checkbox
      :id="id"
      switch
      size="lg"
      v-model="val"
      :disabled="readonly"
      @change="onChange"
    />
  </div>
</template>

<script>
import { FORMCONTROLMODE } from '@/shared/constants'

export default {
  components: {},
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],

      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    mode: {
      type: Number,
      default: FORMCONTROLMODE.VIEW
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      FORMCONTROLMODE: FORMCONTROLMODE,
      isEditMode: false,
      isChanged: false,
      isInvalid: false,
      val: '',
      customError: ''
    }
  },
  computed: {},
  created: function () {
    this.val = this.value

    this.isEditMode =
      (this.mode === FORMCONTROLMODE.EDIT ||
        this.mode === FORMCONTROLMODE.CREATE) &&
      !this.readonly
  },
  mounted: function () {},

  methods: {
    setValue (_value) {
      this.val = _value
    },
    getValue () {
      return this.val
    },
    setMode (_mode) {
      if (_mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = false
      }
      if (_mode !== FORMCONTROLMODE.VIEW) {
        this.isEditMode = true
        this.$refs[this.id].focus()
      }
    },
    test () {
      return true
    },
    onChange (value) {
      let id = this.id.replace(/switch:/g, '')

      this.$emit('changed', id, value)
      this.$emit('input', value)
    }
  },
  watch: {
    value (newVal) {
      this.val = newVal
    }
  }
}
</script>

<style scoped>
.readonly {
  background-color: #e8e8e873 !important;
  cursor: default !important;
}

.form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: #fff;
}
</style>
